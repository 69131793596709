var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "a",
    {
      staticClass: "text-truncate",
      attrs: { href: _vm.item.url, tabindex: "-1" },
    },
    [
      _c("span", {
        domProps: {
          innerHTML: _vm._s(_vm.highlight(_vm.item.title, _vm.value)),
        },
      }),
      _vm._v(" "),
      _c("small", { staticClass: "forum-name text-muted" }, [
        _vm._v("w " + _vm._s(_vm.item.forum.name)),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }