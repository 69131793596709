var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "position-relative" },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.valueLocal,
            expression: "valueLocal",
          },
        ],
        ref: "autocomplete",
        staticClass: "form-control",
        class: { "is-invalid": _vm.errors.length },
        attrs: {
          type: "search",
          autofocus: "",
          id: _vm.id,
          placeholder: _vm.placeholder,
          tabindex: _vm.tabindex,
          autocomplete: "off",
        },
        domProps: { value: _vm.valueLocal },
        on: {
          click: _vm.emitFocus,
          focus: _vm.emitFocus,
          keyup: [
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "up", 38, $event.key, ["Up", "ArrowUp"])
              )
                return null
              $event.preventDefault()
              return _vm.$refs.dropdown.goUp.apply(null, arguments)
            },
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "down", 40, $event.key, [
                  "Down",
                  "ArrowDown",
                ])
              )
                return null
              $event.preventDefault()
              return _vm.$refs.dropdown.goDown.apply(null, arguments)
            },
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              )
                return null
              return _vm.toggleDropdown(false)
            },
          ],
          keydown: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            $event.preventDefault()
            return _vm.changeItem.apply(null, arguments)
          },
          input: function ($event) {
            if ($event.target.composing) return
            _vm.valueLocal = $event.target.value
          },
        },
      }),
      _vm._v(" "),
      _c("vue-dropdown", {
        ref: "dropdown",
        attrs: { items: _vm.items },
        on: { select: _vm.selectItem },
      }),
      _vm._v(" "),
      _c("vue-error", { attrs: { message: _vm.errors } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }