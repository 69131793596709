
import VueUserName from '@/components/user-name.vue';
import VueTimeago from '@/plugins/timeago.js';
import {MessageFolder} from '@/types/models';
import Vue from 'vue';
import VueAvatar from '../avatar.vue';
import {default as mixins} from '../mixins/user.js';

Vue.use(VueTimeago);

export default Vue.extend({
  name: 'VueMessage',
  mixins: [mixins],
  components: {
    'vue-avatar': VueAvatar,
    'vue-username': VueUserName,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
    last: {
      type: Boolean,
      default: false,
    },
    clickableText: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      SENTBOX: MessageFolder.sentbox,
    };
  },
  methods: {
    deleteMessage() {
      this.$confirm({
        message: 'Tej operacji nie będzie można cofnąć.',
        title: 'Usunąć tę wiadomość?',
        okLabel: 'Tak, usuń',
      }).then(() =>
        this.$store.dispatch(`messages/${this.clickableText ? 'trash' : 'remove'}`, this.message),
      );
    },
  },
  computed: {
    isRead() {
      return this.message.folder !== MessageFolder.sentbox ? this.message.read_at !== null : true;
    },
    excerpt() {
      return this.clickableText ? (this.message.excerpt ? this.message.excerpt : '(kliknij, aby przeczytać)') : this.message.text;
    },
  },
});
