var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.saveMicroblog.apply(null, arguments)
        },
      },
    },
    [
      _c("vue-markdown", {
        ref: "markdown",
        attrs: {
          assets: _vm.microblog.assets,
          emojis: _vm.emojis,
          "auto-insert-assets": false,
          "preview-url": "/Mikroblogi/Preview",
        },
        on: {
          "update:assets": function ($event) {
            return _vm.$set(_vm.microblog, "assets", $event)
          },
          save: _vm.saveMicroblog,
          cancel: _vm.cancel,
        },
        scopedSlots: _vm._u([
          {
            key: "bottom",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "p-1" },
                  [
                    _c("vue-tags-inline", {
                      attrs: {
                        tags: _vm.microblog.tags,
                        placeholder: "...inny? kliknij, aby wybrać tag",
                        "popular-tags": _vm.popularTags,
                        "data-popover":
                          '{"message": "Sugerowane tagi, które możesz przypisać do tego wpisu.", "placement": "bottom-start"}',
                      },
                      on: { change: _vm.toggleTag },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.microblog.text,
          callback: function ($$v) {
            _vm.$set(_vm.microblog, "text", $$v)
          },
          expression: "microblog.text",
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "row mt-2" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c(
              "vue-button",
              {
                staticClass: "btn btn-sm btn-primary float-end",
                attrs: {
                  disabled: _vm.isProcessing,
                  title: "Kliknij, aby wysłać (Ctrl+Enter)",
                  tabindex: "2",
                  type: "submit",
                },
                nativeOn: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.saveMicroblog.apply(null, arguments)
                  },
                },
              },
              [_vm._v("\n        Zapisz\n      ")]
            ),
            _vm._v(" "),
            _vm.microblog.id
              ? _c(
                  "button",
                  {
                    staticClass:
                      "btn btn-sm btn-cancel btn-danger float-end me-2",
                    attrs: { title: "Anuluj (Esc)", tabindex: "3" },
                    on: { click: _vm.cancel },
                  },
                  [_vm._v("\n        Anuluj\n      ")]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }