
import Vue from 'vue';
import Session from '../libs/session.js';

type Placement = 'top' | 'bottom' | 'left' | 'right' | 'top-start';

export default Vue.extend({
  name: 'Popover',
  props: {
    message: {
      type: String,
      required: true,
    },
    placement: {
      type: String,
      default: 'bottom',
    },
  },
  methods: {
    closeMessage() {
      let popover = JSON.parse(Session.getItem('popover', '[]'));
      popover.push(this.message);

      Session.setItem('popover', JSON.stringify(popover));

      this.$destroy();
      this.$el.parentNode!.removeChild(this.$el);
    },
  },
});
