var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.shouldShow
    ? _c(
        "button",
        {
          staticClass: "btn btn-follow",
          class: { follows: _vm.follows },
          on: {
            click: function ($event) {
              return _vm.checkAuth(_vm.toggleState)
            },
          },
        },
        [
          _c("i", { staticClass: "fa fa-fw fa-check" }),
          _vm._v(" "),
          _vm._t("default", function () {
            return [
              _vm._v(
                "\n    " +
                  _vm._s(_vm.follows ? "Obserwujesz" : "Obserwuj") +
                  "\n  "
              ),
            ]
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }