var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticStyle: { "z-index": "1" } },
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "div",
          {
            staticClass: "alert alert-warning alert-dismissible mb-0",
            class: _vm.placement,
          },
          [
            _c(
              "button",
              {
                staticClass: "close",
                attrs: {
                  type: "button",
                  "data-dismiss": "alert",
                  "aria-label": "Close",
                  title: "Kliknij, aby zamknąć",
                },
                on: { click: _vm.closeMessage },
              },
              [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
            ),
            _vm._v("\n\n      " + _vm._s(_vm.message) + "\n    "),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }