var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "notification",
      class: { unread: !_vm.isRead },
      attrs: { title: _vm.message.excerpt },
    },
    [
      _c("div", { staticClass: "media" }, [
        _c(
          "a",
          {
            attrs: {
              href: `/Profile/${_vm.message.user.id}`,
              title: "Kliknij, aby wyświetlić profil użytkownika",
            },
          },
          [
            _c("vue-avatar", {
              staticClass: "i-35 me-2",
              attrs: { photo: _vm.message.user.photo },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "a",
          { staticClass: "media-body", attrs: { href: _vm.message.url } },
          [
            _c("header", { staticClass: "text-truncate notification-header" }, [
              _c("h4", [_vm._v(_vm._s(_vm.message.user.name))]),
              _vm._v(" "),
              _c(
                "small",
                [
                  _c("vue-timeago", {
                    attrs: { datetime: _vm.message.created_at },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "p",
              { staticClass: "text-truncate notification-content" },
              [
                _vm.message.folder === _vm.SENTBOX
                  ? [
                      _vm.message.read_at
                        ? _c("i", { staticClass: "fas fa-check" })
                        : _c("span", [_vm._v("Ty: ")]),
                    ]
                  : _vm._e(),
                _vm._v(
                  "\n\n        " + _vm._s(_vm.message.excerpt) + "\n      "
                ),
              ],
              2
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      !_vm.isRead
        ? _c(
            "a",
            {
              staticClass: "btn-action",
              attrs: { href: "javascript:", title: "Oznacz jako przeczytane" },
              on: { click: _vm.mark },
            },
            [_c("i", { staticClass: "fas fa-eye" })]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }