var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "li",
    {
      directives: [
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: _vm.hideDropdown,
          expression: "hideDropdown",
        },
      ],
      staticClass: "nav-item",
      class: { open: _vm.isOpen },
    },
    [
      _c(
        "a",
        {
          staticClass: "nav-link",
          attrs: {
            href: "/User/Pm",
            role: "button",
            "aria-haspopup": "true",
            "aria-expanded": "false",
          },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.loadMessages.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.count > 0,
                  expression: "count > 0",
                },
              ],
              staticClass: "badge",
            },
            [_vm._v(_vm._s(_vm.count))]
          ),
          _vm._v(" "),
          _c("i", { staticClass: "fas fa-envelope fa-fw" }),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isOpen,
              expression: "isOpen",
            },
          ],
          ref: "dropdown",
          staticClass: "dropdown-alerts dropdown-menu dropdown-menu-end",
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "perfect-scrollbar",
            {
              staticClass: "dropdown-modal",
              attrs: {
                options: {
                  wheelPropagation: false,
                  useBothWheelAxes: false,
                  suppressScrollX: true,
                },
              },
            },
            [
              _vm.messages === null
                ? _c("div", { staticClass: "text-center p-3" }, [
                    _c("i", { staticClass: "fas fa-spinner fa-spin" }),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.messages, function (message) {
                return _c("vue-message", {
                  key: message.id,
                  attrs: { message: message },
                })
              }),
              _vm._v(" "),
              Array.isArray(_vm.messages) && _vm.messages.length === 0
                ? _c(
                    "div",
                    { staticClass: "text-center p-3 empty-placeholder" },
                    [_vm._v("\n        Brak wiadomości prywatnych.\n      ")]
                  )
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "dropdown-header" }, [
      _c(
        "a",
        { staticClass: "float-end small", attrs: { href: "/User/Pm/Submit" } },
        [_vm._v("\n        Wyślij wiadomość\n      ")]
      ),
      _vm._v(" "),
      _c(
        "a",
        { attrs: { title: "Przejdź do listy wiadomości", href: "/User/Pm" } },
        [_vm._v("Wiadomości")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }