var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "card card-default microblog",
      attrs: { id: `entry-${_vm.microblog.id}` },
    },
    [
      _c("div", { staticClass: "card-body" }, [
        _vm.microblog.deleted_at
          ? _c("div", { staticClass: "alert alert-danger" }, [
              _vm._v(
                "\n      Ten wpis został usunięty. Możesz go przywrócić jeżeli chcesz.\n    "
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "media" }, [
          _c("div", { staticClass: "d-none d-sm-block me-2" }, [
            _c(
              "a",
              {
                directives: [
                  {
                    name: "profile",
                    rawName: "v-profile",
                    value: _vm.microblog.user.id,
                    expression: "microblog.user.id",
                  },
                ],
              },
              [
                _c(
                  "vue-avatar",
                  _vm._b(
                    {
                      staticClass: "i-45 d-block img-thumbnail",
                      attrs: { "is-online": _vm.microblog.user.is_online },
                    },
                    "vue-avatar",
                    _vm.microblog.user,
                    false
                  )
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "media-body" },
            [
              _c("div", { staticClass: "d-flex flex-nowrap" }, [
                _c("div", { staticClass: "flex-shrink-0 me-auto" }, [
                  _c(
                    "h5",
                    { staticClass: "media-heading" },
                    [
                      _c("vue-username", {
                        attrs: { user: _vm.microblog.user },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ul",
                    {
                      staticClass:
                        "list-inline mb-0 list-inline-bullet-sm microblog-statistic",
                    },
                    [
                      _c("li", { staticClass: "list-inline-item" }, [
                        _c(
                          "a",
                          {
                            staticClass: "small",
                            attrs: { href: _vm.microblog.url },
                          },
                          [
                            _c("vue-timeago", {
                              attrs: { datetime: _vm.microblog.created_at },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "list-inline-item small" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.microblog.views) +
                            "\n                " +
                            _vm._s(
                              _vm.declination(_vm.microblog.views, [
                                "wyświetlenie",
                                "wyświetlenia",
                                "wyświetleń",
                              ])
                            ) +
                            "\n              "
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.microblog.is_sponsored
                        ? _c("li", { staticClass: "list-inline-item small" }, [
                            _vm._v(
                              "\n                Sponsorowane\n              "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "microblog-tags" },
                  [
                    _c("vue-tags", {
                      staticClass: "tag-clouds-md",
                      attrs: { tags: _vm.microblog.tags },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.isAuthorized
                  ? _c("div", { staticClass: "dropdown" }, [
                      _vm._m(0),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "dropdown-menu dropdown-menu-end" },
                        [
                          _vm.microblog.permissions.update
                            ? [
                                !_vm.microblog.deleted_at
                                  ? [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "dropdown-item",
                                          attrs: { href: "javascript:" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.edit(_vm.microblog)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "fas fa-pen-to-square fa-fw",
                                          }),
                                          _vm._v(" Edytuj"),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "dropdown-item",
                                          attrs: { href: "javascript:" },
                                          on: { click: _vm.deleteItem },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "fas fa-trash-can fa-fw",
                                          }),
                                          _vm._v(" Usuń"),
                                        ]
                                      ),
                                    ]
                                  : _c(
                                      "a",
                                      {
                                        staticClass: "dropdown-item",
                                        attrs: { href: "javascript:" },
                                        on: { click: _vm.restoreItem },
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "fas fa-trash-arrow-up fa-fw",
                                        }),
                                        _vm._v(" Przywróć"),
                                      ]
                                    ),
                                _vm._v(" "),
                                _vm.microblog.permissions.moderate &&
                                !_vm.microblog.deleted_at
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "dropdown-item",
                                        attrs: { href: "javascript:" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.toggleSponsored(
                                              _vm.microblog
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "fas fa-dollar-sign fa-fw",
                                        }),
                                        _vm._v(
                                          "\n                  Sponsorowany\n                "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.microblog.user.id !== _vm.user.id
                                  ? _c("div", {
                                      staticClass: "dropdown-divider",
                                    })
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.microblog.user.id !== _vm.user.id
                            ? _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: { href: "javascript:" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.block(_vm.microblog.user)
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "fas fa-fw fa-user-slash",
                                  }),
                                  _vm._v(
                                    "\n                Zablokuj użytkownika\n              "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        2
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.microblog.is_editing,
                      expression: "!microblog.is_editing",
                    },
                  ],
                  class: { "microblog-wrap": _vm.isWrapped },
                },
                [
                  _vm._l(_vm.flags, function (flag) {
                    return _c("vue-flag", {
                      key: flag.id,
                      attrs: { flag: flag },
                    })
                  }),
                  _vm._v(" "),
                  _c("div", {
                    ref: "microblog-text",
                    staticClass: "microblog-text",
                    domProps: { innerHTML: _vm._s(_vm.microblog.html) },
                  }),
                  _vm._v(" "),
                  _vm.opg
                    ? _c(
                        "a",
                        {
                          staticClass: "card microblog-opg",
                          attrs: {
                            href: _vm.opg.metadata.url,
                            title: _vm.opg.metadata.title,
                            target: "_blank",
                          },
                        },
                        [
                          _c("div", {
                            staticClass: "card-img-top",
                            style: `background-image: url(${_vm.opg.url})`,
                            attrs: { alt: _vm.opg.metadata.title },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "card-body" }, [
                            _c(
                              "h5",
                              { staticClass: "card-title text-truncate" },
                              [_vm._v(_vm._s(_vm.opg.metadata.title))]
                            ),
                            _vm._v(" "),
                            _c(
                              "p",
                              { staticClass: "card-text text-truncate" },
                              [_vm._v(_vm._s(_vm.opg.metadata.description))]
                            ),
                            _vm._v(" "),
                            _c("small", { staticClass: "text-muted" }, [
                              _vm._v(_vm._s(_vm.opg.metadata.url)),
                            ]),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.images.length
                    ? _c(
                        "div",
                        { staticClass: "row mb-2" },
                        _vm._l(_vm.images, function (image, imageIndex) {
                          return _c(
                            "div",
                            { key: imageIndex, staticClass: "col-6 col-md-3" },
                            [
                              _c(
                                "a",
                                {
                                  attrs: { href: image.url },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      _vm.index = imageIndex
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    staticClass: "img-thumbnail",
                                    attrs: {
                                      alt: `Załącznik ${imageIndex}`,
                                      src: image.thumb,
                                    },
                                  }),
                                ]
                              ),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isWrapped
                    ? _c(
                        "div",
                        { staticClass: "show-more", on: { click: _vm.unwrap } },
                        [_vm._m(1)]
                      )
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              _vm.microblog.is_editing
                ? _c("vue-form", {
                    ref: "form",
                    staticClass: "mt-2 mb-2",
                    attrs: { microblog: _vm.microblog },
                    on: {
                      cancel: function ($event) {
                        return _vm.edit(_vm.microblog)
                      },
                      save: function ($event) {
                        return _vm.edit(_vm.microblog)
                      },
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "microblog-actions" }, [
                _c(
                  "a",
                  {
                    staticClass: "btn btn-gradient",
                    attrs: {
                      "aria-label": _vm.voters,
                      href: "javascript:",
                      "data-balloon-pos": "up",
                      "data-balloon-break": "",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.checkAuth(_vm.vote, _vm.microblog)
                      },
                      "~mouseenter": function ($event) {
                        return _vm.loadVoters(_vm.microblog)
                      },
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "fa-fw fa-thumbs-up",
                      class: {
                        "fas text-primary": _vm.microblog.is_voted,
                        far: !_vm.microblog.is_voted,
                      },
                    }),
                    _vm._v(
                      "\n\n            " +
                        _vm._s(_vm.microblog.votes) +
                        " " +
                        _vm._s(
                          _vm.declination(_vm.microblog.votes, [
                            "głos",
                            "głosy",
                            "głosów",
                          ])
                        ) +
                        "\n          "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "btn btn-gradient",
                    attrs: {
                      href: "javascript:",
                      title: "Wł/Wył obserwowanie tego wpisu",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.checkAuth(_vm.subscribe, _vm.microblog)
                      },
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "fa-fw fa-bell",
                      class: {
                        "fas text-primary": _vm.microblog.is_subscribed,
                        far: !_vm.microblog.is_subscribed,
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "d-none d-sm-inline" }, [
                      _vm._v("Obserwuj"),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "btn btn-gradient",
                    attrs: {
                      href: "javascript:",
                      title: "Odpowiedz na ten wpis",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.checkAuth(_vm.reply, _vm.microblog.user)
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "far fa-fw fa-comment" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "d-none d-sm-inline" }, [
                      _vm._v("Komentuj"),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "btn btn-gradient",
                    attrs: {
                      href: _vm.microblog.url,
                      title: "Kopiuj link do schowka",
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.copy.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fas fa-share-nodes" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "d-none d-sm-inline" }, [
                      _vm._v("Udostępnij"),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _vm.isAuthorized
                  ? _c(
                      "a",
                      {
                        staticClass: "btn btn-gradient",
                        attrs: {
                          href: "javascript:",
                          "data-metadata": _vm.microblog.metadata,
                          "data-url": _vm.microblog.url,
                          title: "Zgłoś ten wpis",
                        },
                      },
                      [
                        _c("i", { staticClass: "fas fa-flag" }),
                        _vm._v(" "),
                        _c("span", { staticClass: "d-none d-sm-inline" }, [
                          _vm._v("Zgłoś"),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { ref: "comments", staticClass: "microblog-comments" },
                [
                  _vm.microblog.comments_count >
                  Object.keys(_vm.microblog.comments).length
                    ? _c("div", { staticClass: "show-all-comments" }, [
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:" },
                            on: {
                              click: function ($event) {
                                return _vm.loadComments(_vm.microblog)
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "far fa-comments" }),
                            _vm._v(
                              "\n              Zobacz\n              " +
                                _vm._s(
                                  _vm.declination(_vm.totalComments, [
                                    "pozostały",
                                    "pozostałe",
                                    "pozostałe",
                                  ])
                                ) +
                                "\n              " +
                                _vm._s(_vm.totalComments) +
                                "\n              " +
                                _vm._s(
                                  _vm.declination(_vm.totalComments, [
                                    "komentarz",
                                    "komentarze",
                                    "komentarzy",
                                  ])
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.microblog.comments, function (comment) {
                    return _c("vue-comment", {
                      key: comment.id,
                      attrs: { comment: comment },
                      on: { reply: _vm.reply },
                    })
                  }),
                  _vm._v(" "),
                  _vm.isAuthorized
                    ? _c("form", { attrs: { method: "POST" } }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "media microblog-input rounded border-top-0",
                          },
                          [
                            _c("div", { staticClass: "me-2" }, [
                              _c(
                                "a",
                                {
                                  directives: [
                                    {
                                      name: "profile",
                                      rawName: "v-profile",
                                      value: _vm.user.id,
                                      expression: "user.id",
                                    },
                                  ],
                                },
                                [
                                  _c("vue-avatar", {
                                    staticClass: "i-35 d-block img-thumbnail",
                                    attrs: {
                                      photo: _vm.user.photo,
                                      name: _vm.user.name,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "media-body position-relative" },
                              [
                                _c("vue-comment-form", {
                                  ref: "comment-form",
                                  attrs: { microblog: _vm.commentDefault },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("vue-gallery", {
        attrs: { items: _vm.images, index: _vm.index },
        on: {
          close: function ($event) {
            _vm.index = null
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "button",
      {
        staticClass: "btn btn-xs border-0 text-muted",
        attrs: {
          type: "button",
          "data-bs-toggle": "dropdown",
          "aria-label": "Dropdown",
        },
      },
      [_c("i", { staticClass: "fa fa-ellipsis" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("a", { attrs: { href: "javascript:" } }, [
      _c("i", { staticClass: "fa fa-circle-right" }),
      _vm._v(" Zobacz całość"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }