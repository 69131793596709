
import Vue from 'vue';
import VueAvatar from '../../avatar.vue';
import DecoratorMixin from '../mixin';

export default Vue.extend({
  mixins: [DecoratorMixin],
  components: {
    'vue-avatar': VueAvatar,
  },
});
