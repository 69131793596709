
import Vue from 'vue';
import DecoratorMixin from '../mixin';

export default Vue.extend({
  mixins: [DecoratorMixin],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
});
