
import IsImage from '@/libs/assets';
import useBrackets from "@/libs/prompt";
import {User} from '@/types/models';
import Vue from 'vue';
import {mixin as clickaway} from "vue-clickaway";
import VueLightbox from 'vue-cool-lightbox';
import {mapActions, mapGetters, mapState} from "vuex";
import VueClipboard from '../../plugins/clipboard';
import VueTimeago from '../../plugins/timeago.js';
import store from "../../store";
import VueAvatar from '../avatar.vue';
import VueFlag from '../flags/flag.vue';
import {MicroblogMixin} from "../mixins/microblog";
import {default as mixins} from '../mixins/user.js';
import VueTags from '../tags.vue';
import VueUserName from "../user-name.vue";
import VueCommentForm from './comment-form.vue';
import VueComment from "./comment.vue";
import VueForm from './form.vue';

Vue.use(VueTimeago);
Vue.use(VueClipboard);

export default Vue.extend({
  name: 'microblog',
  mixins: [clickaway, mixins, MicroblogMixin],
  store,
  components: {
    'vue-avatar': VueAvatar,
    'vue-username': VueUserName,
    'vue-comment': VueComment,
    'vue-form': VueForm,
    'vue-comment-form': VueCommentForm,
    'vue-gallery': VueLightbox,
    'vue-flag': VueFlag,
    'vue-tags': VueTags,
  },
  props: {
    wrap: {type: Boolean},
  },
  data() {
    return {
      index: null,
      commentDefault: {parent_id: this.microblog.id, text: '', assets: []},
    }
  },
  mounted() {
    if (this.wrap && this.$refs['microblog-text'].clientHeight > 300) {
      this.isWrapped = true;
    }

    const pageHitHandler = () => {
      const rect = this.$refs['microblog-text'].getBoundingClientRect();

      if (rect.top >= 0 && rect.top <= window.innerHeight) {
        document.removeEventListener('scroll', pageHitHandler);
        store.dispatch('microblogs/hit', this.microblog);

        return true;
      }

      return false;
    }

    if (!pageHitHandler()) {
      document.addEventListener('scroll', pageHitHandler);
    }
  },
  methods: {
    ...mapActions('microblogs', ['vote', 'subscribe', 'loadVoters', 'loadComments', 'toggleSponsored']),

    reply(user: User) {
      this.$refs['comment-form'].markdown.value += `@${useBrackets(user.name)}: `;
      this.$refs['comment-form'].markdown.focus();
    },

    unwrap() {
      this.isWrapped = false;
    },

    deleteItem() {
      this.delete('microblogs/delete', this.microblog);
    },

    restoreItem() {
      store.dispatch('microblogs/restore', this.microblog);
    },

    copy() {
      if (this.$copy(this.microblog.url)) {
        this.$notify({type: 'success', text: 'Link prawidłowo skopiowany do schowka.'});
      } else {
        this.$notify({type: 'error', text: 'Nie można skopiować linku do schowka.'});
      }
    },
  },
  computed: {
    ...mapGetters('user', ['isAuthorized']),
    ...mapState('user', ['user']),

    voters() {
      return this.splice(this.microblog.voters);
    },

    totalComments() {
      return this.microblog.comments_count! - Object.keys(this.microblog.comments).length;
    },

    images() {
      return this
        .microblog
        .assets
        .filter(asset => IsImage(asset.name!) && !asset.metadata)
        .map(asset => {
          return {src: asset.url, thumb: asset.thumbnail, url: asset.url};
        });
    },

    opg() {
      return this
        .microblog
        .assets
        .find(asset => asset.metadata !== null)
    },

    flags() {
      return store.getters['flags/filter'](this.microblog.id, 'Coyote\\Microblog');
    },
  },
});
