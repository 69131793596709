var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "media",
      class: {
        "highlight-flash": _vm.highlight,
        "not-read": _vm.comment.is_read === false,
        "border border-danger": _vm.comment.deleted_at,
      },
      attrs: { id: _vm.anchor },
    },
    [
      _c("div", { staticClass: "me-2" }, [
        _c(
          "a",
          {
            directives: [
              {
                name: "profile",
                rawName: "v-profile",
                value: _vm.comment.user.id,
                expression: "comment.user.id",
              },
            ],
          },
          [
            _c(
              "vue-avatar",
              _vm._b(
                {
                  staticClass: "i-35 d-block img-thumbnail",
                  attrs: { "is-online": _vm.comment.user.is_online },
                },
                "vue-avatar",
                _vm.comment.user,
                false
              )
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "media-body d-flex" },
        [
          _vm.comment.is_editing
            ? _c("vue-comment-form", {
                ref: "form",
                staticClass: "w-100 me-1",
                attrs: { microblog: _vm.comment },
                on: {
                  cancel: function ($event) {
                    return _vm.edit(_vm.comment)
                  },
                  save: function ($event) {
                    return _vm.edit(_vm.comment)
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.comment.is_editing
            ? _c(
                "div",
                { staticClass: "w-100" },
                [
                  _c("vue-username", { attrs: { user: _vm.comment.user } }),
                  _vm._v(" "),
                  _vm._l(_vm.flags, function (flag) {
                    return _c("vue-flag", {
                      key: flag.id,
                      attrs: { flag: flag },
                    })
                  }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "comment-text",
                    domProps: { innerHTML: _vm._s(_vm.comment.html) },
                  }),
                  _vm._v(" "),
                  _c(
                    "ul",
                    {
                      staticClass:
                        "d-none d-sm-block list-inline list-inline-bullet-sm microblog-comment-list small m-0",
                    },
                    [
                      _c("li", { staticClass: "list-inline-item" }, [
                        _c(
                          "a",
                          { attrs: { href: _vm.comment.url } },
                          [
                            _c("vue-timeago", {
                              attrs: { datetime: _vm.comment.created_at },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "list-inline-item" }, [
                        _c(
                          "a",
                          {
                            class: { "vote-active": _vm.comment.is_voted },
                            attrs: {
                              "aria-label": _vm.commentVoters,
                              href: "javascript:",
                              "data-balloon-pos": "up",
                              "data-balloon-break": "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.checkAuth(_vm.vote, _vm.comment)
                              },
                              "~mouseenter": function ($event) {
                                return _vm.loadVoters(_vm.comment)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.commentLabel) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "list-inline-item" }, [
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:" },
                            on: {
                              click: function ($event) {
                                return _vm.checkAuth(_vm.reply)
                              },
                            },
                          },
                          [_vm._v("Odpowiedz")]
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.isAuthorized
                        ? _c("li", { staticClass: "list-inline-item" }, [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "javascript:",
                                  "data-metadata": _vm.comment.metadata,
                                  "data-url": _vm.comment.url,
                                },
                              },
                              [_vm._v("Zgłoś")]
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "ul",
                    {
                      staticClass: "d-sm-none list-inline text-muted small m-0",
                    },
                    [
                      _c("li", { staticClass: "list-inline-item" }, [
                        _c(
                          "a",
                          {
                            staticClass: "text-muted",
                            attrs: { href: _vm.comment.url },
                          },
                          [
                            _c("vue-timeago", {
                              attrs: { datetime: _vm.comment.created_at },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "list-inline-item" }, [
                        _vm._m(0),
                        _vm._v(" "),
                        _c("div", { staticClass: "dropdown-menu" }, [
                          _c(
                            "a",
                            {
                              staticClass: "dropdown-item text-muted",
                              attrs: { href: "javascript:" },
                              on: {
                                click: function ($event) {
                                  return _vm.checkAuth(_vm.reply)
                                },
                              },
                            },
                            [_vm._v("Odpowiedz")]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "dropdown-item",
                              class: {
                                "text-primary": _vm.comment.is_voted,
                                "text-muted": !_vm.comment.is_voted,
                              },
                              attrs: {
                                title: _vm.commentVoters,
                                href: "javascript:",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.checkAuth(_vm.vote, _vm.comment)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.commentLabel) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isAuthorized
            ? _c("div", { staticClass: "dropdown" }, [
                _vm._m(1),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "dropdown-menu dropdown-menu-end" },
                  [
                    _vm.comment.permissions.update
                      ? [
                          !_vm.comment.deleted_at
                            ? [
                                _c(
                                  "a",
                                  {
                                    staticClass: "dropdown-item btn-sm-edit",
                                    attrs: { href: "javascript:" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.edit(_vm.comment)
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fas fa-pen-to-square fa-fw",
                                    }),
                                    _vm._v(" Edytuj"),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "a",
                                  {
                                    staticClass: "dropdown-item btn-sm-remove",
                                    attrs: { href: "javascript:" },
                                    on: { click: _vm.deleteItem },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fas fa-trash-can fa-fw",
                                    }),
                                    _vm._v(" Usuń"),
                                  ]
                                ),
                              ]
                            : _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: { href: "javascript:" },
                                  on: { click: _vm.restoreItem },
                                },
                                [
                                  _c("i", {
                                    staticClass: "fas fa-trash-arrow-up fa-fw",
                                  }),
                                  _vm._v(" Przywróć"),
                                ]
                              ),
                          _vm._v(" "),
                          _vm.comment.user.id !== _vm.user.id
                            ? _c("div", { staticClass: "dropdown-divider" })
                            : _vm._e(),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.comment.user.id !== _vm.user.id
                      ? _c(
                          "a",
                          {
                            staticClass: "dropdown-item",
                            attrs: { href: "javascript:" },
                            on: {
                              click: function ($event) {
                                return _vm.block(_vm.comment.user)
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "fas fa-fw fa-user-slash" }),
                            _vm._v(
                              "\n          Zablokuj użytkownika\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  2
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "a",
      {
        staticClass: "text-muted",
        attrs: { href: "#", "data-bs-toggle": "dropdown" },
      },
      [_c("i", { staticClass: "fa fa-bars" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "button",
      {
        staticClass: "btn btn-xs border-0 text-muted",
        attrs: {
          type: "button",
          "data-bs-toggle": "dropdown",
          "aria-label": "Dropdown",
        },
      },
      [_c("i", { staticClass: "small fa fa-ellipsis" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }