var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: _vm.blurInput,
          expression: "blurInput",
        },
      ],
      staticClass: "nav-search",
      class: { "nav-search-mobile": _vm.isMobile },
    },
    [
      _c(
        "div",
        {
          staticClass: "search-bar ms-md-4 me-md-4",
          class: { active: _vm.isActive },
        },
        [
          _c("i", { staticClass: "fas fa-magnifying-glass ms-2 me-2" }),
          _vm._v(" "),
          _c(
            "form",
            {
              ref: "search",
              staticClass: "flex-grow-1",
              attrs: { action: "/Search", role: "search" },
            },
            [
              _vm._l(_vm.params, function ([key, value]) {
                return _c("input", {
                  attrs: { type: "hidden", name: key },
                  domProps: { value: value },
                })
              }),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.value,
                    expression: "value",
                  },
                ],
                ref: "input",
                attrs: {
                  type: "search",
                  name: "q",
                  autocomplete: "off",
                  placeholder: 'Wpisz "?", aby uzyskać pomoc lub wyszukaj',
                },
                domProps: { value: _vm.value },
                on: {
                  focus: _vm.showDropdown,
                  keydown: [
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "esc", 27, $event.key, [
                          "Esc",
                          "Escape",
                        ])
                      )
                        return null
                      $event.preventDefault()
                      return _vm.hideDropdown.apply(null, arguments)
                    },
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      $event.preventDefault()
                      return _vm.changeUrl.apply(null, arguments)
                    },
                  ],
                  input: [
                    function ($event) {
                      if ($event.target.composing) return
                      _vm.value = $event.target.value
                    },
                    _vm.completion,
                  ],
                  keyup: [
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "up", 38, $event.key, [
                          "Up",
                          "ArrowUp",
                        ])
                      )
                        return null
                      $event.preventDefault()
                      return _vm.up.apply(null, arguments)
                    },
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "down", 40, $event.key, [
                          "Down",
                          "ArrowDown",
                        ])
                      )
                        return null
                      $event.preventDefault()
                      return _vm.down.apply(null, arguments)
                    },
                  ],
                  search: _vm.clearInput,
                },
              }),
            ],
            2
          ),
          _vm._v(" "),
          _vm.isMobile
            ? _c(
                "button",
                {
                  staticClass: "btn nav-link",
                  on: { click: _vm.toggleMobile },
                },
                [_c("i", { staticClass: "fa fa-2x fa-xmark" })]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isHelpEnabled
            ? _c("div", { staticClass: "search-dropdown p-3" }, [_vm._m(0)])
            : _vm.isDropdownVisible && _vm.items.length > 0
            ? _c("div", { staticClass: "search-dropdown" }, [
                _c(
                  "ul",
                  { staticClass: "list-unstyled" },
                  [
                    _vm._l(_vm.categories, function (category) {
                      return [
                        _c("li", { staticClass: "title" }, [
                          _c("span", [
                            _vm._v(_vm._s(_vm.categoryLabel(category))),
                          ]),
                        ]),
                        _vm._v(" "),
                        _vm._l(category.children, function (child) {
                          return _c(
                            "li",
                            {
                              class: {
                                hover: child.index === _vm.selectedIndex,
                              },
                              on: {
                                mouseover: function ($event) {
                                  return _vm.hoverItem(child.index)
                                },
                              },
                            },
                            [
                              _c(_vm.makeDecorator(child), {
                                tag: "component",
                                attrs: { item: child, value: _vm.value },
                              }),
                            ],
                            1
                          )
                        }),
                      ]
                    }),
                  ],
                  2
                ),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      !_vm.isMobile
        ? _c("div", { staticClass: "d-md-none navbar-nav ms-auto me-2" }, [
            _c(
              "a",
              {
                staticClass: "nav-link",
                attrs: { href: "javascript:" },
                on: { click: _vm.toggleMobile },
              },
              [_c("i", { staticClass: "fa fa-magnifying-glass fa-fw" })]
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-6 mb-2" }, [
        _c("code", [_vm._v('"foo bar"')]),
        _vm._v(" "),
        _c("small", { staticClass: "text-muted" }, [
          _vm._v("szukaj całych fraz"),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6 mb-2" }, [
        _c("code", [_vm._v("+foo -bar")]),
        _vm._v(" "),
        _c("small", { staticClass: "text-muted" }, [
          _vm._v("wyklucz lub żądaj danego słowa w dokumencie"),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6 mb-2" }, [
        _c("code", [_vm._v("foo*")]),
        _vm._v(" "),
        _c("small", { staticClass: "text-muted" }, [
          _vm._v("szuka fragmentów słów"),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6 mb-2" }, [
        _c("code", [_vm._v("foo~")]),
        _vm._v(" "),
        _c("small", { staticClass: "text-muted" }, [
          _vm._v("szuka podobnych słów"),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6 mb-2" }, [
        _c("code", [_vm._v("user:foo")]),
        _vm._v(" "),
        _c("small", { staticClass: "text-muted" }, [
          _vm._v("szukaj po autorze"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }