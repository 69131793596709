
import Vue from "vue";
import IsImage from "@/libs/assets";
import Textarea from '@/libs/textarea';
import store from "@/store";
import VuePrompt from '../forms/prompt.vue';
import {MicroblogFormMixin} from '../mixins/microblog';

export default Vue.extend({
  name: 'microblog-comment-form',
  store,
  components: {
    'vue-prompt': VuePrompt,
  },
  mixins: [MicroblogFormMixin],
  data() {
    return {
      markdown: null,
    };
  },
  mounted() {
    this.markdown = this.$refs.markdown;
  },
  methods: {
    saveComment() {
      this.save('microblogs/saveComment');
    },
    addAsset(asset) {
      this.microblog.assets.push(asset);
      this.insertAssetAtCaret(asset);
    },
    insertAssetAtCaret(asset) {
      new Textarea(this.markdown).insertAtCaret((IsImage(asset.name) ? '!' : '') + '[' + asset.name + '](' + asset.url + ')', '', '');
      this.markdown.dispatchEvent(new Event('input', {'bubbles': true}));
    },
  },
});
