var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "a",
    {
      staticClass: "text-truncate",
      attrs: { href: _vm.item.url, tabindex: "-1" },
    },
    [_c("span", { domProps: { innerHTML: _vm._s(_vm.highlight(_vm.text)) } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }