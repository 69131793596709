
import declination from '@/libs/declination';
import VueTimeago from '@/plugins/timeago';
import store from "@/store";
import Vue from 'vue';
import {mixin as clickaway} from "vue-clickaway";
import {mapActions, mapGetters, mapState} from "vuex";
import VueAvatar from '../avatar.vue';
import VueFlag from '../flags/flag.vue';
import {MicroblogMixin} from "../mixins/microblog";
import mixins from '../mixins/user.js';
import VueUserName from '../user-name.vue';
import VueCommentForm from "./comment-form.vue";

Vue.use(VueTimeago);

export default Vue.extend({
  name: 'comment',
  mixins: [clickaway, mixins, MicroblogMixin],
  store,
  components: {
    'vue-avatar': VueAvatar,
    'vue-username': VueUserName,
    'vue-comment-form': VueCommentForm,
    'vue-flag': VueFlag,
  },
  props: {
    comment: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions('microblogs', ['vote', 'loadVoters']),
    reply() {
      this.$emit('reply', this.comment.user);
    },
    deleteItem() {
      this.delete('microblogs/deleteComment', this.comment);
    },
    restoreItem() {
      store.dispatch('microblogs/restoreComment', this.comment);
    },
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapGetters('user', ['isAuthorized']),
    anchor() {
      return `comment-${this.comment.id}`;
    },
    highlight() {
      return '#' + this.anchor === window.location.hash;
    },
    commentLabel() {
      return this.comment.votes + ' ' + declination(this.comment.votes, ['głos', 'głosy', 'głosów']);
    },
    commentVoters() {
      return this.splice(this.comment.voters);
    },
    flags() {
      return store.getters['flags/filter'](this.comment.id, 'Coyote\\Microblog');
    },
  },
});
