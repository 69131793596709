var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "vue-modal",
    {
      ref: "modal",
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function () {
            return [_vm._v("\n    Zgłoś materiał\n  ")]
          },
          proxy: true,
        },
        {
          key: "buttons",
          fn: function () {
            return [
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary",
                  attrs: { type: "button" },
                  on: { click: _vm.closeModal },
                },
                [_vm._v("Anuluj")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-danger danger",
                  attrs: {
                    disabled: _vm.isProcessing || _vm.selectedType === null,
                    type: "submit",
                  },
                  on: { click: _vm.sendReport },
                },
                [_vm._v("\n      Wyślij raport\n    ")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _vm._t("default", function () {
        return [
          _vm._l(_vm.types, function (type, index) {
            return _c("div", { key: index, staticClass: "media" }, [
              _c(
                "div",
                { staticClass: "me-2" },
                [
                  _c("vue-radio", {
                    attrs: {
                      name: "type_id",
                      "checked-value": type.id,
                      id: `type-${type.id}`,
                    },
                    model: {
                      value: _vm.selectedType,
                      callback: function ($$v) {
                        _vm.selectedType = $$v
                      },
                      expression: "selectedType",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "media-body" }, [
                _c("i", { staticClass: "me-1", class: _vm.icon(type.name) }),
                _vm._v(" "),
                _c(
                  "label",
                  { staticClass: "fw-bold", attrs: { for: `type-${type.id}` } },
                  [_vm._v(_vm._s(type.name))]
                ),
                _vm._v(" "),
                _c("p", [_vm._v(_vm._s(type.description))]),
              ]),
            ])
          }),
          _vm._v(" "),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.text,
                expression: "text",
              },
            ],
            staticClass: "form-control",
            attrs: { placeholder: "Dodatkowe informacje", name: "text" },
            domProps: { value: _vm.text },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.text = $event.target.value
              },
            },
          }),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }