var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "notification",
      class: { unread: !_vm.notification.is_read },
    },
    [
      _c(
        "div",
        { staticClass: "media", attrs: { title: _vm.notification.headline } },
        [
          _c(
            "a",
            {
              staticClass: "user-link",
              attrs: {
                href: `/Profile/${_vm.notification.user_id}`,
                title: "Kliknij, aby wyświetlić profil użytkownika",
              },
            },
            [
              _c("vue-avatar", {
                staticClass: "i-35 me-2",
                attrs: { photo: _vm.notification.photo },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "media-body text-truncate",
              attrs: { href: _vm.notification.url },
              on: {
                mousedown: function ($event) {
                  return _vm.markAsRead(_vm.notification)
                },
                touchstart: function ($event) {
                  return _vm.markAsRead(_vm.notification)
                },
              },
            },
            [
              _c("header", { staticClass: "notification-header" }, [
                _c("h4", { staticClass: "text-truncate" }, [
                  _vm._v(_vm._s(_vm.notification.headline)),
                ]),
                _vm._v(" "),
                _c(
                  "small",
                  [
                    _c("vue-timeago", {
                      attrs: { datetime: _vm.notification.created_at },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("h3", { staticClass: "notification-subject text-truncate" }, [
                _vm._v(_vm._s(_vm.notification.subject)),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "notification-content text-truncate" }, [
                _vm._v(_vm._s(_vm.notification.excerpt)),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "btn-action",
          attrs: { href: "javascript:", title: "Usuń" },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.deleteNotification(_vm.notification)
            },
          },
        },
        [_c("i", { staticClass: "fas fa-xmark" })]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }