var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "li",
    {
      directives: [
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: _vm.hideDropdown,
          expression: "hideDropdown",
        },
      ],
      class: { open: _vm.isOpen },
    },
    [
      _c(
        "a",
        {
          staticClass: "nav-link",
          attrs: {
            href: "/User/Notifications",
            role: "button",
            "aria-haspopup": "true",
            "aria-expanded": "false",
          },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.toggleDropdown.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.count > 0,
                  expression: "count > 0",
                },
              ],
              staticClass: "badge",
            },
            [_vm._v(_vm._s(_vm.count))]
          ),
          _vm._v(" "),
          _c("i", { staticClass: "fas fa-bell fa-fw" }),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isOpen,
              expression: "isOpen",
            },
          ],
          ref: "dropdown",
          staticClass: "dropdown-alerts dropdown-menu dropdown-menu-end",
        },
        [
          _c("div", { staticClass: "dropdown-header" }, [
            _vm.unreadNotifications.length > 0
              ? _c("div", { staticClass: "float-end" }, [
                  _c(
                    "a",
                    {
                      staticClass: "me-1",
                      attrs: {
                        title: "Otwórz nowe w nowej karcie",
                        href: "javascript:",
                      },
                      on: { click: _vm.openAll },
                    },
                    [_c("i", { staticClass: "fas fa-up-right-from-square" })]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      attrs: {
                        title: "Oznacz jako przeczytane",
                        href: "javascript:",
                      },
                      on: { click: _vm.markAllAsRead },
                    },
                    [_c("i", { staticClass: "far fa-eye" })]
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "a",
              {
                attrs: {
                  title: "Przejdź do listy powiadomień",
                  href: "/User/Notifications",
                },
              },
              [_vm._v("Powiadomienia")]
            ),
          ]),
          _vm._v(" "),
          _c(
            "perfect-scrollbar",
            {
              ref: "scrollbar",
              staticClass: "dropdown-modal",
              attrs: { options: { wheelPropagation: false } },
            },
            [
              _vm.notifications === null
                ? _c("div", { staticClass: "text-center p-3" }, [
                    _c("i", { staticClass: "fas fa-spinner fa-spin" }),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.notifications, function (notification) {
                return _c("vue-notification", {
                  key: notification.id,
                  attrs: { notification: notification },
                })
              }),
              _vm._v(" "),
              Array.isArray(_vm.notifications) && _vm.notifications.length === 0
                ? _c(
                    "div",
                    { staticClass: "text-center p-3 empty-placeholder" },
                    [_vm._v("\n        Brak powiadomień.\n      ")]
                  )
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }