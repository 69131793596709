var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "a",
    {
      staticClass: "d-flex align-content-center text-truncate",
      attrs: { href: _vm.item.url, tabindex: "-1" },
    },
    [
      _c("vue-avatar", {
        staticClass: "i-16 me-2",
        attrs: { photo: _vm.item.photo },
      }),
      _vm._v(" "),
      _c("span", {
        domProps: {
          innerHTML: _vm._s(_vm.highlight(_vm.item.name, _vm.value)),
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "item-options" }, [
        _c(
          "a",
          {
            staticClass: "ms-3",
            attrs: {
              href: _vm.item.url,
              title: "Przejdź do profilu użytkownika",
            },
          },
          [_c("i", { staticClass: "fas fa-user" })]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "ms-3",
            attrs: {
              href: "/User/Pm/Submit?to=" + _vm.item.name,
              title: "Napisz wiadomość",
            },
          },
          [_c("i", { staticClass: "fas fa-comment" })]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "ms-3",
            attrs: {
              href: "/Search?model=Topic&sort=date&user=" + _vm.item.name,
              title: "Znajdź posty użytkownika",
            },
          },
          [_c("i", { staticClass: "fas fa-magnifying-glass" })]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }